.tableContainer {
    display: inline-block;
    width: 80vw;
    margin: 5px 10px 5px 10px;
    overflow-x: scroll;
}

.tableContainer table {
    border-collapse: separate;
}

.tableContainer thead th {
    height: 30px;
    border-right: 1px solid #000;
    min-width: 40px;
}

.tableContainer .top-cell-up {
    border-top: 1px solid #000;
}

.tableContainer .top-cell-down {
    border-bottom: 1px solid #000;
}

.tableContainer thead td {
    text-align: center;
}

.tableContainer th:nth-child(1),
.tableContainer td:nth-child(1) {
    position: sticky;
    left: 0;
    background: #fff;
    min-width: 200px;
    padding-left: 5px;
    border-left: 1px solid #000;
}

.tableContainer td {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.tableContainer tbody tr {
    height: 30px;
}

.tableContainer .leave-cell {
    min-width: 30px;
}

.tableContainer .paid-leave-cell {
    background-color: #01afd9;
}

.tableContainer .unpaid-leave-cell {
    background-color: #d9165c;
}

.tableContainer .other-leave-cell {
    background-color: #9a9a9a;
}

.tableContainer .holiday-cell {
    background-color: #e8e8e8;
}

.tableContainer .today-cell {
    background-color: #3498ff;
    color: #fff;
}

.tableContainer tfoot th {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
}

.tableContainer tfoot td {
    text-align: center;
}

.tableContainer .cell-button {
    background: transparent;
    height: 29px;
    padding: 0;
    width: 100%;
    display: block;
}
